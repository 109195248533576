import { getQueryString } from '@shein/common-function'
import searchWordsGlobalAnalysis from 'public/src/pages/common/biz_helper/gb_sw_analysis'
import { abtservice } from 'public/src/services/abt'

const { IS_RW = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const listAbtSceneStr = [
  'imageLabel',
  'Aod',
  'VideoIcon',
  'HideGoodsNum',
  'listFilterAbt',
  'SameLabel',
  'ShowPromotion',
  'UserBehaviorLabel',
  'listReview', 
  'selectedreview',
  'selectedreviewJson',
  'RankingList',
  'discountLabel',
  'greysellingPoint',
  'sheinappwordsize',
  'goodsSpace',
  'labelColumn',
  'listSpuPic',
]
const searchWordsSceneStr = [
  'Search',
  'SearchDefaultNew',
  'SearchHotGD',
  'SearchSuggestNew',
  'SearchHotNew',
  'ListAttrSequence',
  // 'ListTopLabel', // 已推全-写死值: cloud_tag=1&rule_id=tag_abt:pde|resort:v1|recplt_sr:3
  'Searchfilter',
  'SearchFeedHotword',
  'SearchTopNavigation',
  'ListSearchSort',
  'SearchLeftFilter',
  'SearchPageLimitNum',
  // 'SellOutShow', TODO del
  // 'SearchCategoryWord', 推全type=B
  // 'SearchDef', // 已推全-写死值: none
  // 'HotsearchShowPicture', // 已推全-写死值: type=show
  // 'SearchHot',
  'SearchSuggestwordFeedback',
  // 'CloudTagContent', // 已推全-写死值: cloud_tag=1&rule_id=tag_abt:pde|resort:v1|recplt_debug:1
  'SearchPageSort',
  'searchSpuPic',
  'newpresearch',
  'newpresearchlenovo',
  'newpresearchicon'
]

// 全站搜索才有的abt
const allSearchSceneStr = [ 'SearchCard' ]

function getAbtTestData(listAbtResult = {}, anaScenes) {
  const abtInfo = anaScenes.reduce((acc, cur) => {
    const result = listAbtResult[cur]
    const posKey = result?.sceneStr
    if (posKey) {
      acc[posKey] = result
    }
    
    return acc
  }, {})

  const setNodeAbtData = ['listFilterAbt'] // 服务端的abt数据不请求直接筛选内存中
  setNodeAbtData.forEach(k => { abtservice.useState(k, listAbtResult[k]) })

  if (Object.keys(abtInfo)?.length) {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const { sa } = abtservice.getUserAbtResultForAnalysis({ abtInfo }) || {}
    return sa || ''
  }
  return ''
}

// 埋点 goods_to_list 拼接
/**
 * @param {Object} productData 商品数据
 * @param {number} index 坑位
 * @param {number} compIndex 运营位置
 * @returns 
 */
export const getGoodsToList = (productData, index, compIndex) => {
  // goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`pri_在售价格｜pri_原价`其它标识`销售或促销属性`mall_mallcode`算法额外标识
  if (!productData) return
  const {
    goods_id,
    goods_sn,
    productRelationID,
    mall_code,
    salePrice = {},
    retailPrice = {}
  } = productData
  return [
    goods_id,
    goods_sn, // skc
    productRelationID, // spu_id
    index, // 坑位
    1, // 页码
    compIndex, // 运营位置
    '-', // 流量标识
    '-', // 置顶标识
    `pri_${salePrice?.usdAmount || 0}|pri_${retailPrice?.usdAmount || 0}`, // 价格
    '-', // 其他标识
    '-', // 销售或促销属性
    `mall_${mall_code || '-'}` // mall类型_mallid
  ].join('`')
}

export const ProductListAnalysis = {
  /**
   * 初始化页面埋点信息，赋值window变量，因为别的地方也有在用
   * @param {*} param0 
   */
  init({ 
    catInfo, 
    fhContext,
    googleContext,
    currentCat, 
    searchKeywords, 
    sum,
    goodsCrowId,
    listAbtResult,
    traceId = 0,
    pageParamExtend = {},
    searchRequestStatus,
    searchRecRequestStatus,
    request_ext = {},
    hypernymRequestExt = {},
  }) {
    const { 
      type, 
      attr_ids, 
      sort = 0, 
      ici = 0, 
      tag_ids = 0, 
      min_price, 
      max_price, 
      channel_id, 
      tsp_ids, 
      picks_id, 
      picksType 
    } = catInfo
    const pageId = picksType || (IS_RW ? 9 : 1)
    // const abtest4List = getAbtTestData(listAbtResult, anaScenes)

    /**
     * sa
     * ===============================================================
     */
    let page_id = '',
        page_name = '',
        page_param = {},
        aod_id = '',
        cat_id = ''
    const pageNames = [
      'page_real_class',
      'page_select_class',
      'page_search',
      'page_goods_group'
    ]
    if (ici) {
      const r = ici.split('_').find(i => i.indexOf('aod') > -1) || ''
      aod_id = r.split('=').length && r.split('=')[1] || ''
    }

    /**
     * sa
     * ===============================================================
     */
    switch (type) {
      case 'entity':
        {
          // sa
          page_id = 3
          page_name = 'page_real_class'
          page_param = {
            sort: sort || '',
            result_count: sum,
            aod_id,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
            entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          }
          cat_id = catInfo.entity_id
        }
        break
      case 'selection':
        {
          // sa
          page_id = 46
          page_name = 'page_select_class'
          page_param = {
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
            product_select_id: getQueryString({ key: 'product_select_id' }) || '',
            entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          }
          cat_id = catInfo.select_id
        }
        break
      case 'daily_new':
        {
          // sa
          const now = window.location.href.match(/[\d]{4}-[\d]{1,2}-[\d]{1,2}/)
          page_id = 12
          page_name = 'page_goods_group'
          page_param = {
            group_content: 'daily_new',
            date: (now && now[0]) || '',
            aod_id,
            // abtest: abtest4List,
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
          }
        }
        break
      case 'search':
        {
          const { is_suggest_res, suggest_words, origin_words, suggest_total } = searchKeywords
          const { is_from_list_feeds, search_type } = catInfo

          const abtList = [...listAbtSceneStr, ...searchWordsSceneStr]// 区分全站搜索以及门店搜索结果页面

          if (search_type !== 'store') { 
            abtList.push(...allSearchSceneStr) 
          }

          const abtest = getAbtTestData(listAbtResult, abtList)
          const { result_type, word_type, entrancetype, result_content } = searchWordsGlobalAnalysis.get()

          const query_infor = Object.assign({}, hypernymRequestExt || {}, request_ext || {})
          const queryInforString = Object.entries(query_infor)
            .map(([key, value]) => `${key}_${value ?? ''}`)
            .join('|')

          // sa
          page_id = 16
          page_name = 'page_search'
          page_param = {
            word_from: word_type,
            is_from_list_feeds: String(is_from_list_feeds || 2),
            aod_id,
            channel_id,
            result_type,
            search_content: origin_words || suggest_words,
            result_count: is_suggest_res ? 0 : Number(sum) || 0,
            rec_word: suggest_words,
            rec_count: suggest_total,
            abtest,
            search_request_status: searchRequestStatus,
            search_rec_request_status: searchRecRequestStatus,
            query_infor: queryInforString,
            // 搜索结果页无法直接从url拿到，所以
            entrancetype: entrancetype || '-',
            result_content: result_content || window?.argResultContent || '-',
            ...pageParamExtend
          }
          if (!IS_RW) {
            let crowId = ''
            for (var key in goodsCrowId) {
              crowId += ',' + key + '_' + goodsCrowId[key]
            }
            page_param.crowd_id = crowId.substring(1)
          }
        }
        break
      case 'picks':
        {
          // sa
          page_id = 21
          page_name = 'page_goods_group'
          page_param = {
            group_content: type.replace('-', '_'),
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
            entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          }
        }
        break
      case 'selling':
        {
          // sa
          page_id = 21
          page_name = 'page_goods_group'
          page_param = {
            group_content: 'selling_point',
            // abtest: abtest4List,
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
          }
        }
        break
    }

    /**
     * sa 是否是shein pick页面判断赋值差异
     * ===============================================================
     */
    if (catInfo.type == 'picks') {
      // sa
      page_id = 21
      page_name = 'page_goods_group'
      page_param = {
        group_content: IS_RW ? 'promo_discount' : 'shein_picks',
        child_contentid: pageId || '0',
        result_count: sum,
        styleType: catInfo.styleType,
        ...page_param // 别问，问我也是不知道。。。。
      }
    }

    /**
     * 特定参数修改
     * ===============================================
     */
    const fallPageType = ['entity', 'selection', 'daily_new', 'search', 'picks', 'selling']
    if (fallPageType.includes(type)) {
      // sa
      page_param = {
        ...page_param,
        src_module: catInfo.url_from ? 'ads' : catInfo.src_module || '',
        src_tab_page_id: catInfo.src_tab_page_id || ''
      }

      // 因为有些src_identifier转了有些没转
      // 导致传入src_identifier的字符串没转但带有%符号就会报错
      // 通过捕获错误去修正值
      let src_identifier = ''
      try {
        src_identifier = catInfo.url_from ? `uf=${catInfo.url_from}` : decodeURIComponent(catInfo.src_identifier || '')
      } catch (el) {
        src_identifier = catInfo.url_from ? `uf=${catInfo.url_from}` : (catInfo.src_identifier ?? '')
      }

      page_param.src_identifier = src_identifier
    }
    page_param.pagefrom = ici || ''               // pagefrom
    page_param.tag_id = tag_ids                   // 标签云
    page_param.price_range = `${min_price || '-'}\`${max_price || '-'}`       // 价格

    if (pageNames.includes(page_name)) {
      const { child_cat_id, child_id } = catInfo
      page_param = Object.assign({}, page_param, {
        category_id: ( catInfo.type == 'picks' ? picks_id : cat_id ) || '',
        source_category_id: currentCat.parent_id || '',
        child_id: child_cat_id || child_id || '0',
        pagefrom: ici || '-',
        sort,
        attribute: attr_ids || '0',
        tsps: tsp_ids || '0',
        aod_id,
        sourceId: fhContext.rid,
        attributionToken: googleContext.attributionToken,
      })
    }
    if (type == 'search') {
      const { pagefrom } = searchWordsGlobalAnalysis.get()
      page_param['pagefrom'] = pagefrom || ici?.replace?.('`_fb`', searchKeywords.is_suggest_res ? '`fb1`' : '`fb0`')
      page_param['category_id'] = ''
      page_param['source_category_id'] = ''
    }
    page_param['user_path'] = catInfo.userpath || '-'
    /**
     * 以上那一堆逻辑，就是为了给以下变量赋值
     * ===============================================
     * ===============================================
     * ===============================================
     * ===============================================
     */
    // 1. sa
    window.SaPageInfo = {
      start_time: new Date().getTime(),
      page_id,
      page_name,
      page_param
    }
    if (traceId != 0 && traceId != 1 && typeof gbExposeTraceid !== 'undefined') {
      console.log('### trace_id:' + traceId)
      gbExposeTraceid('setComponent', {
        componentName: 'goodslist',
        traceid: traceId
      })
    }
  },
  /**
   * 发送pv
   */
  sendPageView() {
    //进入页面
    // 1. sa
    if (typeof sa !== 'undefined') {
      sa('set', 'setPageData', window.SaPageInfo)
      sa('send', 'pageEnter', { start_time: new Date().getTime() })

      // 退出页面
      window.onbeforeunload = function () {
        sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }
    }
  },
  /**
   * emarsys
   */
  async pushEmarsysCommend({ catInfo, currentCat, parentCats }) {
    try {
      const res = await import('public/src/services/productRecommend/emarsys/index.js')
      const emarsys = res.default

      if (catInfo.type === 'entity') {
        if (parentCats && Object.keys(parentCats).length > 0) {
          var firstCatName = parentCats.cat_name || (parentCats.multi && parentCats.multi.cat_name) || ''
          if (parentCats.children && Object.keys(parentCats.children).length > 0) {
            var secondCat = parentCats.children[0] || {},
                secondCatName = secondCat.cat_name || (secondCat.multi && secondCat.multi.cat_name) || ''
            if (secondCat.children && Object.keys(secondCat.children).length > 0) {
              var thirdCat = secondCat.children[0] || {},
                  thirdCatName = thirdCat.cat_name || (thirdCat.multi && thirdCat.multi.cat_name) || ''
              if (thirdCat.children && Object.keys(thirdCat.children).length > 0) {
                var forthCat = thirdCat.children[0] || {},
                    forthCatName = forthCat.cat_name || (forthCat.multi && forthCat.multi.cat_name) || ''
                emarsys.pushCommend('category', `${firstCatName} > ${secondCatName} > ${thirdCatName} > ${forthCatName}`)
              } else {
                emarsys.pushCommend('category', `${firstCatName} > ${secondCatName} > ${thirdCatName}`)
              }
            } else {
              emarsys.pushCommend('category', `${firstCatName} > ${secondCatName}`)
            }
          } else {
            emarsys.pushCommend('category', `${firstCatName}`)
          }
        } else if (Object.keys(currentCat).length) {
          emarsys.pushCommend('category', currentCat.cat_name)
        }
      } 
    } catch (error) {
      console.log(error)
    }

  },
}
