<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
import qs from 'query-string'
import { Icon } from '@shein-aidc/icon-vue3'
import { getGoodsToList } from '../analysis'
import ProductCardPriceSimple from '../../components/productItemV3/components/ProductCardPrice/ProductCardPriceSimple.vue'

const props = defineProps({
  /** 店铺数据 */
  storeData: {
    type: Object,
    required: true,
  },
  /** 多语言文案 */
  language: {
    type: Object,
    required: true,
  },
  /** 用于判断是否是付费会员 isPaid*/
  sheinClubInfo: {
    type: Object,
    required: true
  }
})

const { PUBLIC_CDN = '' } = gbCommonInfo

const metas = computed(() => {
  return [
    {
      isShow: parseFloat(props.storeData.score) > 0,
      label: props.language.SHEIN_KEY_PC_21810, // 'Rating',
      value: props.storeData.score,
    },
    {
      isShow: parseFloat(props.storeData.quantity) > 0,
      label: props.language.SHEIN_KEY_PC_21781, // 'Items',
      value: itemsFormat(props.storeData.quantity),
    },
    {
      isShow: parseFloat(props.storeData.followsCount) > 0,
      label: props.language.SHEIN_KEY_PC_25386, // 'Followers',
      value: props.storeData.followsCount,
    },
  ]
})
const itemsFormat = (num) => {
  num = parseInt(num)
  if (num <= 1000) {
    return num
  } else if (num < 10000) {
    return parseInt(num / 100) / 10 + 'K'
  } else if (num <= 99000) {
    return parseInt(num / 1000) + 'K'
  } else {
    return '99K+'
  }
}

const getCommonJumpURLParams = (
  customParams = {}
) => {
  const currentParams = qs.parse(window.location.search) || {}
  
  return qs.stringify({
    ...currentParams,
    page_from: 'PageSearchResult',
    ...customParams,
  })
}

const onClickRecommendProduct = (goodsItem) => {
  const newURL = `/store/home?${
    getCommonJumpURLParams({
      tab: 'items',
      store_code: props.storeData.storeCode,
      adp: goodsItem.goods_id,
      fromSearchResultStoreCard: 1,
      main_goods_id: goodsItem.goods_id,
      main_cate_id: goodsItem?.cat_id || '',
      rule_poskey: 'SearchshopitemList'
    })
  }`

  window.open(newURL, '_blank')
}
const goToStorePage = () => {
  const newURL = `/store/home?${
    getCommonJumpURLParams({
      store_code: props.storeData.storeCode,
      rule_poskey: 'SearchshopitemList'
    })
  }`

  window.open(newURL, '_blank')
}

const sellingPointsSwiper = ref(null)
const recommendCardWidth = 112
const recommendsContainerRef = ref(null)
const isShowRecommends = computed(() => props.storeData.products.length > 5)

const handleResizeForRecommendsContainer = () => {
  if (recommendsContainerRef.value) {
    const containerWidth = recommendsContainerRef.value.offsetWidth
    if (containerWidth < recommendCardWidth) {
      recommendsContainerRef.value.style.cssText = 'visibility: hidden;'
    } else {
      recommendsContainerRef.value.style.cssText = 'visibility: visible;'
    }
  }
}

onMounted(() => {
  const swiperEl = sellingPointsSwiper.value
  if (!swiperEl) return

  const options = {
    modules: [Autoplay],
    direction: 'vertical',
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: props.storeData.storeTags.length > 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    observer: true,
    observeParents: true,
  }
  Object.assign(swiperEl, options)
  swiperEl.initialize()

  // 监听窗口尺寸变化，当 recommendsContainerRef 的宽度小于 recommendCardWidth 时，
  // 说明一个都放不下，则隐藏整个 recommendsContainerRef
  window.addEventListener('resize', handleResizeForRecommendsContainer)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResizeForRecommendsContainer)
})
</script>

<template>
  <article 
    v-expose="{
      id: '1-4-1-20',
      data: {
        store_code: storeData.storeCode,
        store_banner: storeData.bannerImg ? 1 : 0
      }
    }"
    class="search-result-store"
    :style="{
      width: isShowRecommends ? '100%' : 'max-content',
      minWidth: storeData.bannerImg ? '870px' : '',
    }"
  >
    <section 
      v-tap="{
        id: '1-4-1-21',
        data: {
          store_code: storeData.storeCode,
          store_banner: 0
        },
      }"
      class="search-result-store__main"
      @click="goToStorePage()"
    >
      <div class="search-result-store__side">
        <div class="search-result-store__store-logo">
          <img 
            :src="storeData.logo"
            class="search-result-store__store-logo_img"
          />
        </div>
        <div class="search-result-store__store-info">
          <div class="search-result-store__store-info_title">
            <div class="search-result-store__store-info_title-text"> 
              {{ storeData.title }}
            </div>
            <div class="search-result-store__store-info_title-container">
              <div
                v-for="(item, index) in storeData.storeQuality"
                :key="index"
                class="search-result-store__store-info_title-labels"
              >
                <!-- Trends 品质标签 -->
                <div
                  v-if="(item.type === 'trends' && storeData.trendsLabelInfo)"
                  class="search-result-store__store-info_tag header__label-image"
                >
                  <img
                    class="search-result-store__store-info_tag-icon"
                    :src="storeData.trendsLabelInfo?.trendsLabelImg"
                  />
                </div>
                <!-- 品质 店铺标签 -->
                <div
                  v-if="(item.type === 'choices')"
                  class="search-result-store__store-info_tag header__label-image"
                >
                  <img
                    class="search-result-store__store-info_tag-icon"
                    :src="item.icon"
                  />
                </div>
                <!-- 品牌店铺标签 -->
                <div
                  v-if="item.type === 'brand'"
                  class="search-result-store__store-info_tag brand-store"
                >
                  <div class="search-result-store__store-info_tag-icon">
                    <img :src="`${PUBLIC_CDN}/she_dist/images/ccc/branchBannner-icon-52850ee628.svg`" />
                  </div>
                  <div class="search-result-store__store-info_tag-text">
                    {{ language.SHEIN_KEY_PC_25621 }}
                  </div>
                </div>
                <!-- Preferred seller 标签 -->
                <div
                  v-if="item.type === 'preferred'"
                  class="search-result-store__store-info_tag preferred-seller"
                >
                  <img
                    :src="`${PUBLIC_CDN}/she_dist/images/search_result_store_header/preferred-seller-medal-befaf5491a.svg`"
                    class="search-result-store__store-info_tag-icon preferred-seller-medal"
                  />
                  <span class="search-result-store__store-info_tag-text preferred-seller-text">
                    <!-- Preferred Seller -->
                    {{ language.SHEIN_KEY_PC_21837 }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="search-result-store__store-info_metas">
            <template
              v-for="meta in metas"
              :key="meta.label"
            >
              <span
                v-if="meta.isShow"
                class="search-result-store__store-info_metas-item"
              >
                <span class="search-result-store__store-info_metas-label">{{ meta.label }}:</span>
                <span class="search-result-store__store-info_metas-value">{{ meta.value }}</span>
              </span>
            </template>
          </div>

          <!-- 商品卖点标签轮播 -->
          <swiper-container
            ref="sellingPointsSwiper"
            init="false"
            destroy-on-disconnected="false"
            class="search-result-store__store-info_selling-tags"
          >
            <swiper-slide
              v-for="tag in storeData.storeTags"
              :key="tag.description"
              class="search-result-store__store-info_selling-tags-item"
            >
              <i
                :class="[
                  `sh_pc_${tag.icon}`,
                  'search-result-store__store-info_selling-tags-item__icon',
                ]"
              ></i>
              <span class="search-result-store__store-info_selling-tags-item__text">
                {{ tag.title }}
              </span>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>

      <template v-if="isShowRecommends">
        <div
          ref="recommendsContainerRef"
          class="search-result-store__store-recommends"
        >
          <div 
            v-for="(item, i) in storeData.products.slice(0, storeData.products.length - 1)"
            :key="item.goods_id"
            v-tap="{
              id: '1-4-1-21',
              data: {
                goods_to_list: getGoodsToList(item, i, 1),
                store_code: storeData.storeCode,
              },
            }"
            class="search-result-store__store-recommends-item"
            :class="{ 'search-result-store__store-recommends-item--last': i === storeData.products.length -1 }"
            @click.stop="onClickRecommendProduct(item)"
          > 
            <img
              :src="item.goods_img"
              class="search-result-store__store-recommends-item__img"
            />
            <ProductCardPriceSimple
              class="search-result-store__store-recommends-item__price"
              :goods-info="item"
              :language="language"
              :is-paid="!!sheinClubInfo?.isPaid" 
              :config="{
                hidePriceBottomLabel: true,
                noCamelCasePrice: true,
                priceColor: storeData.priceColor
              }"
            />
          </div>
        </div>
        <div
          v-tap="{
            id: '1-4-1-21',
            data: {
              store_code: storeData.storeCode,
            },
          }"
          class="search-result-store__store-recommends-item"
        >
          <img
            :src="storeData.products[storeData.products.length - 1].goods_img"
            class="search-result-store__store-recommends-item__img"
          />
          <div class="search-result-store__store-recommends-item__mask">
            <!-- Enter -->
            <span>{{ language.SHEIN_KEY_PC_21650 }}</span>
            <Icon
              class="search-result-store__store-recommends-item__mask-arrow"
              name="sui_icon_more_circle_right_16px_1"
              size="16px"
            />
          </div>
        </div>
      </template>
      <div
        v-else
        class="search-result-store__store-entry-btn"
      >
        {{ language.SHEIN_KEY_PC_21650 }}
      </div>
    </section>
    <section 
      v-if="storeData.bannerImg"
      v-tap="{
        id: '1-4-1-21',
        data: {
          store_code: storeData.storeCode,
          store_banner: 1
        },
      }"
      class="search-result-store__banner"
      @click="goToStorePage()"
    >
      <section
        class="search-result-store__banner-img"
        :style="'background-image: url('+ storeData.bannerImg +');'"
      >
      </section>
    </section>
  </article>
</template>

<style lang="less">

.search-result-store {
  .flex() {
    display: flex;
    align-items: center;
  }
  width: 100%;
  max-width: 1486px;
  background-color: #FAFAFA;
  padding: 20px 20px;
  margin-bottom: 30px;
  margin-left: 0;

  &__main{
    .flex;
    cursor: pointer;
    width: 100%;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &:hover {
    background: var(---sui_color_gray_weak1a, #F2F2F2);
  }
  
  &__side {
    .flex;
    flex: 1;
    margin-right: 20px;
    cursor: pointer;
  }

  &__store-logo {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    flex-shrink: 0;
    background-color: #fff;
    border: 0.5px solid #E5E5E5;

    &_img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  
  &__store-info {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: calc(100% - 120px);

    &_title {
      display: flex;
      align-items: center;
      
      // &-text {
      //   font-weight: 700;
      //   font-size: 22px;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
    }
    &_title-text {
      font-weight: 700;
      font-size: 22px;
      margin-right: 8px;
      max-width: 253px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_title-container {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      height: 20px;
      overflow: hidden;
    }
    &_title-labels:last-child {
      .search-result-store__store-info_tag {
        margin-right: 0;
      }
    }

    &_tag {
      .flex;
      margin-right: 10px;
      height: 20px;
      // &-icon {
      //   .flex;
      //   width: 12px;
      //   height: 12px;
      //   margin-right: 2px;
      // }
      
      // &-text {
      //   font-size: 12px;
      //   font-family: Helvetica, Arial, sans-serif;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   line-height: 1;
      // }

      &.preferred-seller {
        padding: 4px 5px;
        background-color: #FFF8EB;

        .search-result-store__store-info_tag-text {
          color: #A86104;
        }
      }

      &.brand-store {
        border-radius: 2px;
        border: 0.5px solid #BCC8D3;
        background: linear-gradient(129deg, #E2EDF3 7.72%, #F0F8FF 34.23%, #DAE7F6 98.52%);
        padding: 2px 3px;
        border-radius: 2px; 

        .search-result-store__store-info_tag-text {
          color: #2D3540;
          font-weight: 500;
        }
      }

      &.local-seller {
        background-color: #f5fcfb;

        .search-result-store__store-info_tag-text {
          color: #198055;
        }
      }

      &.header__label-image {
        .search-result-store__store-info_tag-icon {
          width: auto;
          height: 20px;
          margin-right: 0;
        }
      }
    }
    &_tag-icon {
      .flex;
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
      
    &_tag-text {
      font-size: 12px;
      font-family: Helvetica, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
    }


    &_metas {
      margin-top: 10px;
      font-size: 14px;
      font-family: 'SF UI Text';

      // &-label {
      //   color: #666666;
      // }
      
      // &-item {
      //   margin-right: 12px;
      // }

      // &-value {
      //   margin-left: 2px;
      //   font-weight: 700;
      // }
    }
    &_metas-label {
      color: #666666;
    }
    
    &_metas-item {
      margin-right: 12px;
    }

    &_metas-value {
      margin-left: 2px;
      font-weight: 700;
    }

    &_selling-tags {
      .flex;
      flex-direction: column;
      overflow: hidden;
      height: 16px;
      margin-top: 10px;
      margin-left: 0;
      color: #A86104;
      width: 100%;

      /* stylelint-disable */
      &-item {
        .flex;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        font-size: 14px;
        overflow: hidden;
        &__icon {
          width: 14px;
          height: 14px;
          margin-right: 2px;
          font-size: 14px !important;
        }

        &__text {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      /* stylelint-enable */
    }
  }

  &__store-recommends {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(112px, 1fr));
    grid-template-rows: repeat(auto-fit, 112px);
    max-width: calc(100% - 480px - 112px);
    grid-auto-flow: dense;
    row-gap: 8px;
    column-gap: 8px;
    margin-right: 8px;
    margin-left: auto;
    overflow: hidden;
    height: 114px;
    
    /* stylelint-disable */
    &-item {
      width: 112px;
      height: 112px;
      min-width: 112px;
      cursor: pointer;
      position: relative;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.20);

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__price {
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(0.5px);
        padding: 1px 4px;
        font-weight: 700;
        font-size: 14px;
        position: absolute;
        bottom: 6px;
        right: 6px;
        z-index: 2;

        &.is-sale-price {
          color: var(---sui_color_discount, #FA6338);
        }
      }
      
      &__mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000099;
        z-index: 2;
        top: 0;
        left: 0;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: capitalize;

        &-arrow {
          margin-left: 4px;
        }
      }
    }
    /* stylelint-enable */
  }

  &__store-entry-btn {
    .flex;
    justify-content: center;
    height: 28px;
    min-width: 90px;
    padding: 9px 16px;
    color: #fff;
    background-color: #000;
    cursor: pointer;
  }

  &__banner {
    cursor: pointer;
    margin-top: 8px;
    min-height: 95.8px;
    max-height: 167px;
    height: 9.876vw;
    // &-img {
    //   height: 100%;
    //   width: 100%;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  }
  &__banner-img {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.sh_pc_sui_icon_hot_12px {
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS45OTk1OSAxMC40NjkzQzQuMjAyNCAxMC40NjkzIDIuNjY1NDQgOC44NjU2NiAyLjc5MzIgNy4wMjU0NEMyLjk4OTEgNC42NjE5MyA1LjE5NDI5IDQuMzAzMjYgNC44NzA2MyAxLjI4NTY0QzUuNDM3MiAxLjg1OTYyIDcuMjI5NzYgMy45NjQyMiA3LjIyOTc2IDUuODgxODRDOC4xMjQxIDUuMTU3OTYgOC4wODEyIDQuMzQ2ODcgOC4wODEyIDMuMzkwMjRDOS4wMjU0OCA0LjcyOTUyIDkuMjE0MzQgNS42ODEyNSA5LjIxNDM0IDcuMTkwMDZDOS4yMDU4MiA5LjAzMDI4IDcuNzk2NzkgMTAuNDY5MyA1Ljk5OTU5IDEwLjQ2OTNaIiBmaWxsPSIjQTg2MTA0Ij4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
  background-size: 100%;
}
/* stylelint-disable-next-line selector-class-pattern */
.sh_pc_sui_icon_like_completed_24px {
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTkuMDg3NiAyMC41OTUySDcuNVY5LjczODFDOS45Mjc5NCA4LjE3NyAxMC42ODMyIDYuNDQ4OTQgMTEuMjg0NyA1LjA3Mjg5QzExLjkzNzYgMy41NzkgMTIuNDA5MiAyLjUgMTQuNjQyOSAyLjVDMTguNTIzNyAyLjUgMTUuOTM5NyA4Ljg4MDk1IDE1LjkzOTcgOC44ODA5NUgxOC43OTRDMjAuMzI0OCA4Ljg4MDk1IDIxLjQ1NzggMTAuMzA0NiAyMS4xMTQyIDExLjc5NjNMMTkuMDg3NiAyMC41OTUyWiIgZmlsbD0iI0E4NjEwNCI+CjwvcGF0aD4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zIDkuNzM4MDRWMjAuNTk1Mkg2VjkuNzM4MDRIM1oiIGZpbGw9IiNBODYxMDQiPgo8L3BhdGg+Cjwvc3ZnPg==) no-repeat;
  background-size: 100%;
}
/* stylelint-disable-next-line selector-class-pattern */
.sh_pc_sui_icon_me_fill_16px {
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOC4wMDAwMSA4LjEzOTY0QzkuOTUzMTYgOC4xMzk2NCAxMS41MzY1IDYuNTU2MyAxMS41MzY1IDQuNjAzMTVDMTEuNTM2NSAyLjY0OTk5IDkuOTUzMTYgMS4wNjY2NSA4LjAwMDAxIDEuMDY2NjVDNi4wNDY4NiAxLjA2NjY1IDQuNDYzNTIgMi42NDk5OSA0LjQ2MzUyIDQuNjAzMTVDNC40NjM1MiA2LjU1NjMgNi4wNDY4NiA4LjEzOTY0IDguMDAwMDEgOC4xMzk2NFoiIGZpbGw9IiNBODYxMDQiPgo8L3BhdGg+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy45OTk5OSA5LjE1MDA1QzExLjIwOTcgOS4xNTAwNSAxMy44NzU2IDExLjQ3ODggMTQuNCAxNC41MzlIMS41OTk5OEMyLjEyNDQyIDExLjQ3ODggNC43OTAyNCA5LjE1MDA1IDcuOTk5OTkgOS4xNTAwNVoiIGZpbGw9IiNBODYxMDQiPgo8L3BhdGg+Cjwvc3ZnPg==) no-repeat;
  background-size: 100%;
  width: 16px;
  height: 16px;
}
/* stylelint-disable-next-line selector-class-pattern */
.search-result-store__store-recommends-item__price {
  .price-content {
    /* stylelint-disable-next-line declaration-no-important */
    padding-right: 0 !important;
  }
}
</style>
